////////////////////////////////////////////////////////////////////////////////
// Devuelve la fecha que le llega conmo parámetro (fec) en formato europeo
//    largo
//    medio
//    corto
// Euskera creo que es "eu"
function FechaLargaEsp(fec, idioma) {
  if (idioma === "Es") {
    let fecha = fec.toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    return (fecha.substr(0, 1).toUpperCase() + fecha.substr(1))
  }
  else {
    let f = fec;
    let dias = ["Igande", "Astelehen", "Astearte", "Asteazken", "Ostegun", "Ostiral", "Larunbat"];
    let meses = ["urtarril", "otsail", "martxo", "apiril", "maiatz", "ekain", "uztail", "abuztu", "irail", "urri", "azaro", "abendu"];
    // cadena con el año aaaa
    let ano = "" + f.getFullYear();
    // ultimo dígito 0123
    let dig = ano.substr(3);
    // terminación del año
    let terAno = "ko";
    // si el año termina en consonante
    // x1 (bat, hogeita bat) excepto 11 (hamaika)
    // y derivados de 11: 31,51,71,91 (xx hogeita hamaika)
    // x5 (bost)
    if (dig === "1" || dig === "5") {
      terAno = "eko";
    }
    // dia del mes
    let dia = f.getDate()
    // terminación del día
    let terDia = "a";
    // día 11 (amaika) o 31 (hogeita amaika)
    if (dia === 11 || dia === 31) {
      terDia = "";
    }
    return (dias[f.getDay()] + "a, " + ano + terAno + " " + meses[f.getMonth()] + "aren " + dia + terDia);

    //return(fec.toLocaleDateString('eu-EU', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))

    //return(fec.toLocaleDateString('fa-IR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))
  }
}

function FechaMediaEsp(fec) {
  return (fec.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }))
}
function FechaCortaEsp(fec) {
  return (fec.toLocaleDateString('es-ES'))
}

function FechaAaaaMmDd(fec, inc) {
  fec = fec === undefined ? new Date() : fec
  inc = inc === undefined ? 0 : inc * 1000 * 24 * 60 * 60

  fec = new Date(fec.getTime() + inc)
  return StrZero(fec.getFullYear(), 4) + '-' + StrZero((fec.getMonth() + 1), 2) + '-' + StrZero(fec.getDate(), 2)
}


function FechaDdMmAaaa(fec, inc) {
  fec = fec === undefined ? new Date() : fec
  inc = inc === undefined ? 0 : inc * 1000 * 24 * 60 * 60

  fec = new Date(fec.getTime() + inc)
  return StrZero(fec.getDate(), 2) + '-' + StrZero((fec.getMonth() + 1), 2) + '-' + StrZero(fec.getFullYear(), 4)
}


function FechaAaMmDdHhMmSs(fec) {
  fec = fec === undefined ? new Date() : fec

  fec = new Date(fec.getTime())
  return StrZero(fec.getFullYear(), 2) + StrZero((fec.getMonth() + 1), 2) + StrZero(fec.getDate(), 2) + StrZero(fec.getHours(), 2) + StrZero(fec.getMinutes(), 2) + StrZero(fec.getSeconds(), 2)
}


function FechaAaaaMmDdHhMm(fec) {
  fec = fec === undefined ? new Date() : fec

  fec = new Date(fec.getTime())
  return StrZero(fec.getFullYear(), 4) + '-' + StrZero((fec.getMonth() + 1), 2) + '-' + StrZero(fec.getDate(), 2) + ' ' + StrZero(fec.getHours(), 2) + ':' + StrZero(fec.getMinutes(), 2)
}



///function FechaDdMmAaaa( fec, idioma ) {
///  return idioma === 'Es' ? fec.substr(8,2)+"-"+fec.substr(5,2)+"-"+fec.substr(0,4) : fec.substr(0,10)
///}

/////////////////////////////////////////////////////////////////////////////////////////
// Devuelve el número que le llega como parámetro (num) con puntos de miles y decimales
//   Si dec llega con -1, se supone que hay que devolver los decimales que trajera
//   Si no llega dec, se suponen que 2
//
function NumeroEsp(num, dec) {
  // Si no viene el parametros de decimales, poner 2
  dec = dec === undefined ? 2 : dec
  if (dec === -1) {
    num = "" + num
    let pos = num.indexOf('.')
    if (pos === -1) {
      dec = 0
    } else {
      dec = num.substr(pos + 1).length
      dec = dec > 4 ? 4 : dec
    }
  }
  // Forzar que es un numero. //
  num = Number(num)

  return (num.toLocaleString('es-ES', { minimumFractionDigits: dec, maximumFractionDigits: dec }))
}


//////////////////////////////////////////////////////////////////////////////////////////////////////
// Devuelve el contador de un elemento complejo buscado en un array complejo, o -1 si no lo encuentra
//    lista: es el array complejo
//    clave: el la clave del objeto del array en el que hay que buscar
//    dato: es el dato a buscar (el primero encontrado)
//
function BuscaIndice(lista, clave, dato) {
  for (let index = 0; index < lista.length; index++) {
    if (lista[index][clave] === dato) return index;
  }
  return -1;
}

//////////////////////////////////////////////////////////////////
// Devuelve un array complejo ordenado por un campo
//    lista: es el array complejo
//    campo: es el campo del elemento del array por el que hay que ordenar
//
function OrdenaArray(lista, campo) {
  return (lista.sort(function (a, b) {
    if (a[campo] > b[campo]) {
      return 1
    }
    if (a[campo] < b[campo]) {
      return -1
    }
    return 0
  }))
}
function OrdenaArrayInverso(lista, campo) {
  return (lista.sort(function (a, b) {
    if (a[campo] < b[campo]) {
      return 1
    }
    if (a[campo] > b[campo]) {
      return -1
    }
    return 0
  }))
}


//////////////////////////////////////////////////////////////////
// Devuelve un array complejo ordenado por dos campos
//    lista: es el array complejo
//    campo1: es el primer campo del elemento del array por el que hay que ordenar
//    campo2: es el segundo campo del elemento del array por el que hay que ordenar
//
function OrdenaArray2Campos(lista, campo1, campo2) {
  return (lista.sort(function (a, b) {
    if (a[campo1] + a[campo2] > b[campo1] + b[campo2]) {
      return 1
    }
    if (a[campo1] + a[campo2] < b[campo1] + b[campo2]) {
      return -1
    }
    return 0
  }))
}

//////////////////////////////////////////////////////////////////
// Devuelve un array recibiendo un Objeto JSON
//    lista: es el array complejo
//    campo: el el campo del elemento del array por el que hay que ordenar
//
function JsonAArray(objetoJson) {
  return (Object.keys(objetoJson).map(function (elemento) {
    return [elemento, objetoJson[elemento]];
  }))
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Devuelve la frase que le llega como parámetro (str) con las primeras letras de cada palabra en mayúscula y el resto en minúscula
function MayusMinus(str) {
  let output = ""
  let proximoMay = true
  for (var i = 0; i < str.length; i++) {
    let ch = str[i]
    if (ch === ' ') {
      proximoMay = true
      output += ch
    }
    else {
      if (proximoMay) {
        output += ch.toUpperCase();
      }
      else {
        output += ch.toLowerCase();
      }
      proximoMay = false
    }
  }
  return output
}


////
// Comprueba la existencia de un fichero de Imagen
function ExistImagen(path) {
  let retorno = true
  let img = new Image()
  img.src = path
  img.onerror = function () {
    retorno = false
  }
  console.log(path, ' -- ', retorno)
  return retorno

}

function StrZero(s, l) {
  return ("0000000000" + s).slice(-l)
}


//Calcula la distancia entre 2 puntos en
function DistanciaKm(posOrigen, posDestino) {
  let Radio = 6371  // 6378.137  // Radio terrestre en km.

  let resultadoKm
  let la1 = posOrigen.la
  let lo1 = posOrigen.lo
  let la2 = posDestino.la
  let lo2 = posDestino.lo

  if (la1 === la2 && lo1 === lo2) {
    resultadoKm = 0
  } else {
    resultadoKm = Radio * Math.acos(Math.cos(radianes(90 - la1)) * Math.cos(radianes(90 - la2)) + Math.sin(radianes(90 - la1)) * Math.sin(radianes(90 - la2)) * Math.cos(radianes(lo1 - lo2)))
  }
  return resultadoKm


  /* // Este otro fuente calcula lo mismo
    var R = 6371; // Radio de la tierra en km.
    var dLat = radianes(la2-la1);
    var dLon = radianes(lo2-lo1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(radianes(la1)) * Math.cos(radianes(la2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var b = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * b; // Distancia in km
    return d;
  */
}

// grados a radianes.
function radianes(v) {
  return (Math.PI / 180) * v //  0.01745 * v
}



function CadenaAleatoria(longitud) {
  // Nota: no uses esta función para cosas criptográficamente seguras. 
  const banco = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let aleatoria = ""
  for (let i = 0; i < longitud; i++) {
    aleatoria += banco.charAt(Math.floor(Math.random() * banco.length))
  }
  return aleatoria
}




export {
  DistanciaKm,
  FechaLargaEsp,
  FechaMediaEsp,
  FechaCortaEsp,
  FechaAaaaMmDd,
  //FechaDdMmAaaa,
  FechaDdMmAaaa,
  FechaAaMmDdHhMmSs,
  FechaAaaaMmDdHhMm,
  NumeroEsp,
  BuscaIndice,
  OrdenaArray,
  OrdenaArrayInverso,
  OrdenaArray2Campos,
  JsonAArray,
  MayusMinus,
  ExistImagen,
  StrZero,
  CadenaAleatoria
}

